import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
// import "./ProjectCards.css"; // Make sure to import the CSS file

function ProjectCards(props) {
    return (
        <Card className="project-card-view">
            <Card.Img
                variant="top"
                src={props.imgPath}
                alt="card-img"
                className="card-img-fixed-height"
                style={props.imgStyle}
            />
            <Card.Body className="d-flex flex-column"> {/* Add flexbox layout */}
                <div className="flex-grow-1"> {/* Content area */}
                    <Card.Title>{props.title}</Card.Title>
                    <Card.Text style={{ textAlign: "justify" }}>
                        {props.description}
                    </Card.Text>
                </div>
                <div className="mt-3"> {/* Button area */}
                    <Button variant="primary" href={props.ghLink} target="_blank">
                        <BsGithub /> &nbsp;
                        {props.isBlog ? "Code" : "GitHub"}
                    </Button>
                    {"\n"}
                    {"\n"}

                    {!props.isBlog && props.demoLink && (
                        <Button
                            variant="primary"
                            href={props.demoLink}
                            target="_blank"
                            style={{ marginLeft: "10px" }}
                        >
                            <CgWebsite /> &nbsp;
                            {"Demo"}
                        </Button>
                    )}
                </div>
            </Card.Body>
        </Card>
    );
}
export default ProjectCards;
